.App-header {
  font-family: 'Tourney', cursive;
}

.btt-primary {
  display: inline-flex;
  cursor: pointer;
  justify-content: center;
  white-space: nowrap;
  border-radius: 0.125rem;
  border-width: 0px;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  font-weight: 700;
  line-height: 1rem;
  text-decoration: none;
}

.h1-primary-color {
  color: #BF1E2D !important;
}

.h1-variant-color {
  color: #BF1E2D !important;
  font-size: 1.75rem !important;
}

@media (min-width: 600px) {
  .App-link {
    font-size: 2rem;
  }
}